

































































































































import { appName, merchantURL, appUrl } from "@/env";
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfile } from "@/interfaces";
import { readUserProfile } from "@/store/main/getters";
import { dispatchBuildings } from "@/store/admin/actions";
import _ from "lodash";
import moment, { duration } from "moment-timezone";
import Apply from "../../Apply.vue";
import EditUser from "./EditUser.vue";
import UploadUserDoc from "./UploadUserDoc.vue";
import EditAddress from "./EditAddress.vue";
import AdminViewUser from "./AdminViewUser.vue";
import JsonCSV from "vue-json-csv";
@Component({
  components: {
    Apply,
    JsonCSV,
    AdminViewUser,
    EditUser,
    UploadUserDoc,
    EditAddress,
  },
})
export default class SalesReport extends Vue {
  public dialog = false;

  public onlyAdmin = false;
  public onlyPaid = false;

  public headers() {
    return [
      {
        text: "Condo Code",
        sortable: true,
        value: "condo_code",
        align: "left",
      },
      {
        text: "Name",
        sortable: true,
        value: "name",
        align: "left",
      },
      {
        text: "Floors",
        sortable: true,
        value: "num_floors",
        align: "left",
      },
      {
        text: "Units",
        sortable: true,
        value: "num_units",
        align: "left",
      },
      {
        text: "Splynx Name",
        sortable: true,
        value: "project_name",
        align: "left",
      },
      {
        text: "Latitude",
        sortable: true,
        value: "latitude",
        align: "left",
      },
      {
        text: "Longitude",
        sortable: true,
        value: "longitude",
        align: "left",
      },

      {
        text: "OSP Progress",
        sortable: true,
        value: "osp_progress",
        align: "left",
      },
      {
        text: "OSP Status",
        sortable: true,
        value: "osp_status",
        align: "left",
      },
      {
        text: "MOA Status",
        sortable: true,
        value: "moa_status",
        align: "left",
      },
      {
        text: "Feasible",
        sortable: true,
        value: "is_feasible",
        align: "left",
      },
      {
        text: "Active",
        sortable: true,
        value: "is_active",
        align: "left",
      },
      {
        text: "Deleted",
        sortable: true,
        value: "is_deleted",
        align: "left",
      },
      {
        text: "Is Listed on Google Map",
        sortable: true,
        value: "is_on_google",
        align: "left",
      },
      {
        text: "Splynx Project ID",
        sortable: true,
        value: "splynx_project_id",
        align: "left",
      },
      {
        text: "Splynx Location ID",
        sortable: true,
        value: "splynx_location_id",
        align: "left",
      },
      {
        text: "Record Updated Date",
        sortable: true,
        value: "updated_date",
        align: "left",
      },
      {
        text: "Record Created Date",
        sortable: true,
        value: "created_date",
        align: "left",
      },
    ];
  }

  public search = "";
  public search2 = "";
  public buildings = [];
  public userCSV = [];
  public promoCSV = [];
  public promos = [];
  public user_id = 0;
  public scroll_item = "";
  public onlyActive = true;

  toggleOnlyActive() {
    this.onlyActive = !this.onlyActive;
  }
  stringify(item) {
    return JSON.stringify(item);
  }
  created() {
    if (location.host.startsWith("dev")) {
      document.title = "Dev: Building Status Report";
    } else {
      document.title = "Building Status Report";
    }
  }
  public async mounted() {
    const data = await dispatchBuildings(this.$store);
    this.buildings = _.get(data, "data.data.buildings", []);
    const userFields = this.headers().map((header) => header.value);
    this.userCSV = this.buildings.map((user) => {
      const item = {};
      console.log(user);
      this.headers().forEach((header) => {
        item[header.text] = _.get(user, header.value, "");
      });
      return item;
    });
    console.log(data);

    //console.log(this.promos);
    console.log("buildings", this.buildings);
  }

  diffDates(date1, date2) {
    return moment(date2).diff(moment(date1), "days");
  }
  formatDate(date) {
    if (date) {
      return moment
        .utc(date)
        .tz("Asia/Manila")
        .format("MMM DD, YYYY HH:mm:ss");
    }
    return "";
  }
  formatDateOnly(date) {
    if (date) {
      return moment
        .utc(date)
        .tz("Asia/Manila")
        .format("MMM DD, YYYY");
    }
    return "";
  }
  async editItem(user_id, scroll_item = null) {
    this.user_id = user_id;
    this.scroll_item = scroll_item;
  }
}
