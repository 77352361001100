var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" Building Status Report ")]),_c('v-spacer')],1),_c('v-card',[_c('br'),_c('div',{staticStyle:{"margin-left":"auto","text-align":"right"}},[_c('JsonCSV',{staticClass:"btn btn-default",attrs:{"name":"buildings.csv","data":_vm.userCSV}},[_c('v-btn',[_vm._v("Export CSV")])],1)],1),_c('br'),_c('v-layout',{attrs:{"align-center":"","justify-center":""}}),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers(),"items":_vm.buildings,"search":_vm.search,"multi-sort":"","loading-text":"Loading... Please wait","options":{
        itemsPerPage: 100,
      },"items-per-page":100,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100, 1000],
      }},scopedSlots:_vm._u([{key:"item.user.first_name",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editItem(item.user.id)}}},[_vm._v(" "+_vm._s(item.user.first_name)+" ")])]}},{key:"item.promo_cost",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editItem(item.user.id, 'promo_costs')}}},[_vm._v(" "+_vm._s(item.promo_cost)+" ")])]}},{key:"item.user.created_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.user.created_date))+" ")]}},{key:"item.first_billing.created_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.first_billing.created_date))+" ")]}},{key:"item.user.is_eligible",fn:function(ref){
      var item = ref.item;
return [(item.user.is_eligible)?_c('v-icon',{attrs:{"width":"20"}},[_vm._v("check")]):_vm._e()]}},{key:"item.upgraded_plan",fn:function(ref){
      var item = ref.item;
return [(item.upgraded_plan)?_c('v-icon',{attrs:{"width":"20"}},[_vm._v("check")]):_vm._e()]}},{key:"item.user.activation_date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.user.activation_date))+" ")]}},{key:"item.contract_expiry",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.contract_expiry))+" ")]}},{key:"item.user.progress_description",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.progress_description)+" ")]}},{key:"item.lead_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.diffDates( item.first_billing.created_date, item.user.activation_date ))+" ")]}},{key:"item.sales_lead_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.diffDates(item.user.created_date, item.first_billing.created_date))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"info",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("View user")])]}},{key:"item.user.referral",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.referral)+" "+_vm._s(item.user.referral_other)+" "),(!!item.user.referral_cid)?_c('div',[_vm._v(" (Referral CID "+_vm._s(item.user.referral_cid)+") ")]):_vm._e()]}},{key:"item.referred_signups",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.referred_signups .filter(function (refer) { return !!refer.referred_user_splynx_cid; }) .map(function (refer) { return refer.referred_user_splynx_cid; }) .join(","))+" ")]}},{key:"item.referred_paid_signups",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.referred_paid_signups .filter(function (refer) { return !!refer.referred_user_splynx_cid; }) .map(function (refer) { return refer.referred_user_splynx_cid; }) .join(","))+" ")]}},{key:"item.market_segment",fn:function(ref){
      var item = ref.item;
return [(!!item.market_segment)?_c('div',[_vm._v(" "+_vm._s(item.market_segment.segment)),_c('br'),_vm._v(" "+_vm._s(item.market_segment.description)),_c('br')]):_vm._e()]}},{key:"item.promo.promo_data",fn:function(ref){
      var item = ref.item;
return [(!!item.promo)?_c('div',[_vm._v(_vm._s(_vm.stringify(item.promo))),_c('br')]):_vm._e()]}}])})],1),_c('AdminViewUser',{attrs:{"user_id":_vm.user_id,"scroll_item":_vm.scroll_item}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }